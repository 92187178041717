<template>
  <div style="width: 100%">
    <el-form
      ref="postForm"
      label-position="right"
      label-width="90px"
      :model="postData"
      :rules="rules"
      size="small"
      @submit.native.prevent="handleSubmit"
    >
      <input disabled style="display:none" />
      <!-- 
        解决360浏览器自动填充
        填充时 360浏览器会查找type=password的input
        如果找到，则向上找最近的一个DOM进行用户名填充
        此时可能会导致组件外的input填充内容，导致其他问题        
      -->
      <el-form-item label="新密码" prop="pwd">
        <el-input v-model="postData.pwd" type="password"></el-input>
      </el-form-item>
      <el-form-item label="重复密码" prop="pwd1">
        <el-input v-model="postData.pwd1" type="password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit" :loading="loading">提交</el-button>
        <el-button @click="
            () => {
              this.$emit('end');
            }
          ">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {editMyPwd} from '@/api/index.js';
import md5 from '@/utils/md5.js';
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.postData.pwd1 !== '') {
          this.$refs.postForm.validateField('pwd1');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.postData.pwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      postData: {
        pwd: '',
        pwd1: '',
      },
      rules: {
        account: [this.$rules.required],
        pwd: [this.$rules.required, {validator: validatePass, trigger: 'blur'}],
        pwd1: [
          this.$rules.required,
          {validator: validatePass2, trigger: 'blur'},
        ],
      },
    };
  },
  methods: {
    handleSubmit() {
      this.$refs['postForm'].validate((valid) => {
        let postData = JSON.parse(JSON.stringify(this.postData));
        postData.pwd = md5(postData.pwd, 32);
        postData.pwd1 = md5(postData.pwd1, 32);
        postData.userPwd = postData.pwd;
        postData.userPwd1 = postData.pwd1;
        if (valid) {
          this.loading = true;
          this.$utils.resolveRequest(editMyPwd(postData), this);
        } else {
          this.loading = false;
          this.$message.warning('请填写正确信息！');
          return false;
        }
      });
    },
  },
};
</script>

<style></style>
